





























































import Clock from '@/components/PremiumCoupon/ExpendableCoupon/Clock.vue';
import { PremiumExpendableCoupon } from '@/models/PremiumExpendableCoupon';
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';

@Component({ components: { Clock } })
export default class CouponDialogStep3 extends Vue {
  @Prop() readonly coupon!: PremiumExpendableCoupon;
  @Prop() readonly count!: number;
  @Prop() readonly remainingQuantityBefore!: number;
  @PropSync('dialog') dialog_!: boolean;
}
