import Utils from '@/common/utils';
import axios, { AxiosResponse } from 'axios';
import { CMSResponse, PremiumExpendableCouponResponse } from '@/models/cms';
import {
  ConfigurationParameters,
  CouponApi,
  ResGetLimitedExpendableCouponHistories
} from '@/gen';
import { IPremiumExpendableCouponHistoryRepository } from '@/repositories/interface/IPremiumExpendableCouponHistoryRepository';
import { PremiumExpendableCouponHistory } from '@/models/PremiumExpendableCouponHistory';
import { fetchListContents } from '@/common/cms';

export class PremiumExpendableCouponHistoryRepository
  implements IPremiumExpendableCouponHistoryRepository {
  private readonly api: CouponApi;
  private readonly path = 'limited-expendable-coupons';

  constructor(config?: ConfigurationParameters) {
    this.api = new CouponApi(config);
  }

  async getList(
    limit: number,
    offset: number
  ): Promise<CMSResponse<PremiumExpendableCouponHistory>> {
    // 本メソッドは、次のリクエストを順番に実行する。
    //
    // 1. ONE の限定消込クーポン消込履歴取得 API に対して、limit, offset 指定でリクエストする
    // 2. microCMS の限定消込クーポン API に対して、limit, filters (id) 指定でリクエストする
    //
    // ONE API からはクーポンの名称が返却されないため、microCMS へのリクエストが必須となる。
    // 何らかの理由で、あるクーポンについて、ONE からのレスポンスに含まれ、
    // microCMS からのレスポンスに含まれない場合、当該クーポンの名称は空文字列とする。

    let resp1: AxiosResponse<ResGetLimitedExpendableCouponHistories>;
    let resp2: AxiosResponse<CMSResponse<PremiumExpendableCouponResponse>>;

    try {
      resp1 = await this.api.getLimitedExpendableCouponHistories(limit, offset);
    } catch (error) {
      if (Utils.isAppError(error)) {
        throw error.response.data.code;
      }
      if (axios.isAxiosError(error)) {
        throw error.response?.status;
      }
      throw undefined;
    }

    try {
      resp2 = await fetchListContents<PremiumExpendableCouponResponse>(
        this.path,
        {
          limit,
          filters: resp1.data.contents
            .map(c => `id[equals]${c.coupon_id}`)
            .join('[or]')
        }
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error.response?.status;
      }
      throw undefined;
    }

    const histories: PremiumExpendableCouponHistory[] = resp1.data.contents.map(
      content1 => {
        const content2 = resp2.data.contents.find(
          content2 => content2.id === content1.coupon_id
        );
        const name = content2 ? content2.name : '';
        return new PremiumExpendableCouponHistory(
          content1.coupon_id,
          name,
          new Date(content1.use_dt),
          content1.use_num
        );
      }
    );

    return {
      limit: resp1.data.limit,
      offset: resp1.data.offset,
      totalCount: resp1.data.total_count,
      contents: histories
    };
  }
}
