























import { Vue, Component, PropSync } from 'vue-property-decorator';

@Component
export default class CouponListToggle extends Vue {
  @PropSync('state') state_!: 'AVAILABLE' | 'UNAVAILABLE';
}
