var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"mdlCouFoot"},[_c('h4',{staticClass:"mdlCouFoot_title"},[_vm._v("発行対象サービス")]),_c('ul',{staticClass:"mdlCouFoot_list"},_vm._l((_vm.couponDistributionPackages),function(subscriptionPackage){return _c('li',{key:subscriptionPackage.id},[_c('p',{staticClass:"mdlCouFoot_name"},[_vm._v(_vm._s(subscriptionPackage.name))]),(_vm.$auth.isAuthenticated)?_c('p',{staticClass:"mdlCouFoot_link"},[(_vm.isUserRegistered(subscriptionPackage.id))?_c('span',{staticClass:"mdlCouFoot_link_btn mdlCouFoot_link_btn-Reg u-btn"},[_vm._v(" ご利用中 ")]):[_c('router-link',{staticClass:"mdlCouFoot_link_btn u-btn u-btn-grad",attrs:{"to":{
              name: 'register-subscription',
              params: { id: subscriptionPackage.id }
            },"aria-disabled":!_vm.isRegistrationOpen(subscriptionPackage.id)}},[_vm._v(" "+_vm._s(_vm.isRegistrationOpen(subscriptionPackage.id) ? '申し込む' : '申し込み受付終了')),_c('i',{staticClass:"icon icon-linkRight"})])]],2):_vm._e(),_c('ul',{staticClass:"mdlCouFoot_cauList"},[(_vm.getSubscriptionPackageEndDt(subscriptionPackage.id))?_c('li',[_vm._v(" ※"+_vm._s(_vm._f("formatYmdHm")(_vm.getSubscriptionPackageEndDt(subscriptionPackage.id)))+" 「"+_vm._s(_vm.getSubscriptionPackageName(subscriptionPackage.id))+"」 サービス終了にともなうコンテンツのご利用に関する詳細は "),_c('router-link',{attrs:{"to":{
              name: 'subscription-lp',
              params: { id: subscriptionPackage.id }
            }}},[_vm._v("こちら")])],1):_vm._e(),(
            !_vm.isUserRegistered(subscriptionPackage.id) &&
              _vm.isRegistrationOpen(subscriptionPackage.id) &&
              _vm.getRegistrationCloseDt(subscriptionPackage.id)
          )?_c('li',[_vm._v(" ※"+_vm._s(_vm._f("formatYmdHm")(_vm.getRegistrationCloseDt(subscriptionPackage.id)))+"までお申し込み可能です。 ")]):_vm._e()])])}),0),(!_vm.$auth.isAuthenticated)?_c('button',{staticClass:"mdlCouFoot_btn u-btn u-btn-grad",on:{"click":function($event){return _vm.$emit('login', _vm.coupon.id)}}},[_vm._v(" ログイン"),_c('i',{staticClass:"icon icon-linkRight"})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }