








































































import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  getSubscribedPackageIds,
  getSubscriptionPackageEndDt,
  getSubscriptionPackageName,
  getRegistrationCloseDt
} from '@/common/subscriptionUtils';
import { INFO_SUBSCRIPTION_CLAIMKEY } from '@/common/constants';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';
import dayjs from 'dayjs';

@Component
export default class CouponDialogFooter extends Vue {
  @Prop() readonly coupon!: {
    id: string;
    subscriptionPackages: { id: string; name: string }[];
  };
  @Prop({ required: true }) readonly packageList!: Array<
    SubscriptionPackageItem
  >;

  isUserRegistered(subscriptionPackageID: string): boolean {
    return getSubscribedPackageIds(
      this.$auth.user ? this.$auth.user[INFO_SUBSCRIPTION_CLAIMKEY] : null
    ).includes(subscriptionPackageID);
  }

  getSubscriptionPackageEndDt(id: string): string {
    return getSubscriptionPackageEndDt(this.packageList, id);
  }

  getSubscriptionPackageName(id: string): string {
    return getSubscriptionPackageName(this.packageList, id);
  }

  getRegistrationCloseDt(id: string): string {
    return getRegistrationCloseDt(this.packageList, id);
  }

  isRegistrationOpen(id: string): boolean {
    const registrationCloseDt = this.getRegistrationCloseDt(id);
    return registrationCloseDt
      ? dayjs().isBefore(dayjs(registrationCloseDt))
      : true;
  }

  // 表示しているクーポンの配布を行うパッケージの内、サービス提供中のもののみ表示する。
  get couponDistributionPackages() {
    return this.coupon.subscriptionPackages.filter(
      p => !dayjs().isAfter(this.getSubscriptionPackageEndDt(p.id))
    );
  }
}
