
































import CouponListItemDialog from '@/components/PremiumCoupon/OnlineCoupon/CouponListItemDialog.vue';
import { PremiumOnlineCoupon } from '@/models/PremiumOnlineCoupon';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';

@Component({ components: { CouponListItemDialog } })
export default class CouponItem extends Vue {
  @Prop() readonly dialog?: boolean;
  @Prop() coupon!: PremiumOnlineCoupon;
  @Prop() readonly state!: 'AVAILABLE' | 'UNAVAILABLE';
  @Prop({ required: true }) readonly packageList!: Array<
    SubscriptionPackageItem
  >;

  dialog_ = !!this.dialog;

  onClick(): void {
    this.dialog_ = true;
    this.$dataLayer.push({
      coupon_title: this.coupon.name,
      event: 'coupon-click'
    });
  }
}
