import Utils from '@/common/utils';
import axios from 'axios';
import { CMSResponse, PremiumExpendableCouponResponse } from '@/models/cms';
import { ConfigurationParameters, CouponApi } from '@/gen';
import { IPremiumExpendableCouponRepository } from '@/repositories/interface/IPremiumExpendableCouponRepository';
import { PremiumExpendableCoupon } from '@/models/PremiumExpendableCoupon';
import { convertOptimizedUrl } from '@/common/cmsUtils';
import { getFirstDistributionDt } from '@/common/couponUtils';
import { fetchListContents, selectContent } from '@/common/cms';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
const TIMEZONE_UTC = 'UTC';

export class PremiumExpendableCouponAvailableRepository
  implements IPremiumExpendableCouponRepository {
  private readonly api: CouponApi;

  // cache は本リポジトリが内部的に保持する限定消込クーポンの配列である。
  // 本リポジトリの get() または getList() が最初に呼び出されたとき、
  // 本リポジトリは ONE API を呼び出し、レスポンスを cache に格納する。
  // 2 回目以降 get() または getList() が呼び出されたとき、本 API は
  // ONE API を呼び出すことはせず、内部的に保持している cache の部分配列を
  // 返す。
  private cache?: PremiumExpendableCoupon[];

  constructor(config?: ConfigurationParameters) {
    this.api = new CouponApi(config);
  }

  async get(id: string): Promise<PremiumExpendableCoupon> {
    // 内部的に呼び出す ONE 限定消込クーポン取得 API はページネーション機能を持たず、
    // 長さが高々 100 である配列情報を返す。
    // get() にて取得する限定消込クーポンは、この長さ 100 以下の配列に含まれることが
    // 期待される。
    const limit = Number.POSITIVE_INFINITY;
    const offset = 0;

    // this.cache === undefined であるとき (未だ getList() が一度も呼び出されたことがないとき)
    // ONE 限定消込クーポン取得 API を呼び出し、結果を this.cache に格納する。
    if (this.cache === undefined) {
      const resp = await this.getList(limit, offset);
      this.cache = resp.contents;
    }

    const coupon = this.cache.find(c => c.id === id);
    if (coupon) {
      return coupon;
    }
    throw undefined;
  }

  async getList(
    limit: number,
    offset: number,
    options: { storeID?: string } = {}
  ): Promise<CMSResponse<PremiumExpendableCoupon>> {
    // this.cache === undefined であるとき (未だ getList() が一度も呼び出されたことがないとき)
    // ONE 限定消込クーポン取得 API を呼び出し、結果を this.cache に格納する。
    if (this.cache === undefined) {
      try {
        const resp = await this.api.getLimitedExpendableCoupons();
        this.cache = resp.data.map(
          d =>
            new PremiumExpendableCoupon(
              d.coupon_id,
              d.coupon_name,
              d.summary,
              d.explanation,
              d.caution,
              d.auth_code,
              d.use_limit_num_per_day,
              d.used_num_today,
              d.thumbnail,
              d.main_image,
              d.logo,
              d.subscription_packages.map(p => ({
                id: p.subscription_package_id,
                name: p.subscription_package_name
              })),
              d.stores?.map(s => ({ id: s.store_id, name: s.store_name })),
              d.once_flg,
              new Date(d.distribute_start_dt),
              new Date(d.distribute_end_dt),
              new Date(d.absolute_expire_dt),
              d.validity_months,
              d.distribute_interval,
              d.issue_num,
              d.distributions.map(d => ({
                subscriptionPackage: {
                  id: d.subscription_package_id,
                  name: d.subscription_package_name
                },
                activationDt: new Date(d.activate_dt),
                expirationDt: new Date(d.expire_dt),
                remainingQuantity: d.remain_use_num
              })),
              d.next_distributions.map(d => ({
                subscriptionPackage: {
                  id: d.subscription_package_id,
                  name: d.subscription_package_name
                },
                dt: new Date(d.next_distribute_dt)
              })),
              getFirstDistributionDt(d.distribute_interval)
            )
        );
      } catch (error) {
        if (Utils.isAppError(error)) {
          throw error.response.data.code;
        }
        if (axios.isAxiosError(error)) {
          throw error.response?.status;
        }
        throw undefined;
      }
    }

    let contents = this.cache.slice();

    // storeID が指定されたとき、当該店舗を消込店舗として持つ
    // クーポンに限定して返却する。
    const storeID = options.storeID;
    if (storeID) {
      contents = contents.filter(c => c.hasStore(storeID));
    }

    // totalCount は offset, limit による slice()
    // の直前に取得されるべきである。
    const totalCount = contents.length;

    contents = contents.slice(offset, offset + limit);

    return { contents, limit, offset, totalCount };
  }

  async use(
    couponID: string,
    usageQuantity: number,
    remainingQuantity: number,
    expenditureCode?: string
  ): Promise<void> {
    try {
      await this.api.postLimitedExpendableCouponUse({
        coupon_id: couponID,
        use_num: usageQuantity,
        remain_use_num: remainingQuantity,
        expend_cd: expenditureCode
      });
    } catch (error) {
      if (Utils.isAppError(error)) {
        throw error.response.data.code;
      }
      if (axios.isAxiosError(error)) {
        throw error.response?.status;
      }
      throw undefined;
    }
  }
}

export class PremiumExpendableCouponUnavailableRepository
  implements IPremiumExpendableCouponRepository {
  private readonly path = 'limited-expendable-coupons';

  constructor(
    private readonly isSP: boolean,
    private readonly supportsWebP: boolean,
    private readonly activeSubscriptionPackageIDs: string[]
  ) {}

  async get(id: string): Promise<PremiumExpendableCoupon> {
    try {
      const resp = await selectContent<PremiumExpendableCouponResponse>(
        this.path,
        id
      );
      const coupon = new PremiumExpendableCoupon(
        resp.data.id,
        resp.data.name,
        resp.data.summary,
        resp.data.explanation,
        resp.data.caution,
        resp.data.authCode,
        resp.data.useLimitNumPerDay,
        0, // usage amount for today
        convertOptimizedUrl(resp.data.thumbnail, this.isSP, this.supportsWebP),
        convertOptimizedUrl(resp.data.mainImage, this.isSP, this.supportsWebP),
        convertOptimizedUrl(resp.data.logo, this.isSP, this.supportsWebP),
        resp.data.subscriptionPackages.map(p => ({
          id: p.id,
          name: p.packageName
        })),
        resp.data.stores?.map(s => ({ id: s.id, name: s.storeName })),
        resp.data.onceFlg,
        new Date(resp.data.distributeStartDt),
        new Date(resp.data.distributeEndDt),
        new Date(resp.data.absoluteExpireDt),
        resp.data.validityMonths,
        resp.data.distributeInterval,
        resp.data.issueNum,
        [], // distributions
        [], // next distributions
        getFirstDistributionDt(resp.data.distributeInterval)
      );
      // 本リポジトリは利用不可能な限定消込クーポンを取得するためのリポジトリであるため、
      // microCMS から利用可能なクーポン情報が返されたとき、undefined を throw する。
      // なお、この throw は直下の catch によりキャッチされる。
      if (coupon.isAvailable(this.activeSubscriptionPackageIDs)) {
        throw undefined;
      }
      return coupon;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error.response?.status;
      }
      throw undefined;
    }
  }

  async getList(
    limit: number,
    offset: number,
    options: { storeID?: string } = {}
  ): Promise<CMSResponse<PremiumExpendableCoupon>> {
    const conditions: string[] = [];
    const storeID = options.storeID;
    if (storeID) {
      conditions.push(`stores[contains]${storeID}`);
    }
    conditions.push(
      ...this.activeSubscriptionPackageIDs.map(
        id => `subscriptionPackages[not_contains]${id}`
      )
    );
    conditions.push(`distributeEndDt[greater_than]${this.getCurrentTimeUTC()}`);

    const filters = conditions.join('[and]');

    try {
      const resp = await fetchListContents<PremiumExpendableCouponResponse>(
        this.path,
        { limit, offset, filters }
      );
      const contents = resp.data.contents.map(
        c =>
          new PremiumExpendableCoupon(
            c.id,
            c.name,
            c.summary,
            c.explanation,
            c.caution,
            c.authCode,
            c.useLimitNumPerDay,
            0, // usage amount for today
            convertOptimizedUrl(c.thumbnail, this.isSP, this.supportsWebP),
            convertOptimizedUrl(c.mainImage, this.isSP, this.supportsWebP),
            convertOptimizedUrl(c.logo, this.isSP, this.supportsWebP),
            c.subscriptionPackages.map(p => ({
              id: p.id,
              name: p.packageName
            })),
            c.stores?.map(s => ({ id: s.id, name: s.storeName })),
            c.onceFlg,
            new Date(c.distributeStartDt),
            new Date(c.distributeEndDt),
            new Date(c.absoluteExpireDt),
            c.validityMonths,
            c.distributeInterval,
            c.issueNum,
            [], // distributions
            [], // next distributions
            getFirstDistributionDt(c.distributeInterval)
          )
      );
      return {
        limit: resp.data.limit,
        offset: resp.data.offset,
        totalCount: resp.data.totalCount,
        contents
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error.response?.status;
      }
      throw undefined;
    }
  }

  async use(): Promise<void> {
    // 使用不可能な限定消込クーポンに対して use() が呼ばれた場合、
    // 何もせず、undefined で reject する。
    throw undefined;
  }

  /**
   * 現在時刻をUTCで取得する
   */
  private getCurrentTimeUTC() {
    return dayjs()
      .tz(TIMEZONE_UTC)
      .format();
  }
}
