import { intersection } from 'lodash';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';
import { getSubscriptionPackageEndDt } from '@/common/subscriptionUtils';
import dayjs from 'dayjs';

const DISPLAY_PATTERN_DEFAULT = 0,
  DISPLAY_PATTERN_GO_TO_EXTERNAL_PAGE = 1,
  DISPLAY_PATTERN_COMING_SOON = 3,
  DISPLAY_PATTERN_GO_TO_SERVICE_PAGE = 4;

export class PremiumOnlineCoupon {
  constructor(
    public id: string,
    public name: string,
    public effect: string,
    public explanation: string,
    public summary: string,
    public detail: string | undefined,
    public mainImageURL: string,
    public thumbnailURL: string,
    public logoURL: string,
    public code: string | undefined,
    public url: string | undefined,
    public displayPattern: number,
    public subscriptionPackages: { id: string; name: string }[]
  ) {}

  get isDisplayPatternDefault(): boolean {
    return this.displayPattern === DISPLAY_PATTERN_DEFAULT;
  }

  get isDisplayPatternGoToExternalPage(): boolean {
    return this.displayPattern === DISPLAY_PATTERN_GO_TO_EXTERNAL_PAGE;
  }

  get isDisplayPatternComingSoon(): boolean {
    return this.displayPattern === DISPLAY_PATTERN_COMING_SOON;
  }

  get isDisplayPatternGoToServicePage(): boolean {
    return this.displayPattern === DISPLAY_PATTERN_GO_TO_SERVICE_PAGE;
  }

  isAvailable(activeSubscriptionPackageIDs: string[]): boolean {
    const targetIDs = this.subscriptionPackages.map(p => p.id);
    return intersection(activeSubscriptionPackageIDs, targetIDs).length > 0;
  }

  getSubscriptionPackages(
    packageList: Array<SubscriptionPackageItem>
  ): { id: string; name: string }[] {
    return this.subscriptionPackages.filter(
      p => !dayjs().isAfter(getSubscriptionPackageEndDt(packageList, p.id))
    );
  }
}
