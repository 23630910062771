import Utils from '@/common/utils';
import axios from 'axios';
import { CMSResponse, PremiumOnlineCouponResponse } from '@/models/cms';
import { CouponApi, ConfigurationParameters } from '@/gen';
import { IPremiumOnlineCouponRepository } from '@/repositories/interface/IPremiumOnlineCouponRepository';
import { PremiumOnlineCoupon } from '@/models/PremiumOnlineCoupon';
import { convertOptimizedUrl } from '@/common/cmsUtils';
import { fetchListContents, selectContent } from '@/common/cms';

export class PremiumOnlineCouponAvailableRepository
  implements IPremiumOnlineCouponRepository {
  private readonly api: CouponApi;

  constructor(config?: ConfigurationParameters) {
    this.api = new CouponApi(config);
  }

  async get(id: string): Promise<PremiumOnlineCoupon> {
    const limit = 1;
    const offset = 0;
    const resp = await this.getList(limit, offset, id);

    // 万一指定していないIDのクーポンが返却された場合、無視する。
    resp.contents = resp.contents.filter(c => c.id === id);
    // 指定された id の利用可能な限定オンラインクーポンが存在しないとき、
    // undefined で reject する。
    if (resp.contents.length === 0) {
      throw undefined;
    }

    return resp.contents[0];
  }

  async getList(
    limit: number,
    offset: number,
    id?: string
  ): Promise<CMSResponse<PremiumOnlineCoupon>> {
    try {
      const resp = await this.api.getLimitedOnlineCoupons(limit, offset, id);
      return {
        limit: resp.data.limit,
        offset: resp.data.offset,
        totalCount: resp.data.total_count,
        contents: resp.data.contents.map(
          c =>
            new PremiumOnlineCoupon(
              c.id,
              c.name,
              c.effect,
              c.explanation,
              c.summary,
              c.detail,
              c.main_image,
              c.thumbnail,
              c.logo,
              c.code,
              c.url,
              c.display_pattern,
              c.subscription_packages.map(p => ({
                id: p.subscription_package_id,
                name: p.subscription_package_name
              }))
            )
        )
      };
    } catch (error) {
      if (Utils.isAppError(error)) {
        throw error.response.data.code;
      }
      if (axios.isAxiosError(error)) {
        throw error.response?.status;
      }
      throw undefined;
    }
  }
}

export class PremiumOnlineCouponUnavailableRepository
  implements IPremiumOnlineCouponRepository {
  private readonly path = 'limited-online-coupons';

  constructor(
    private readonly isSP: boolean,
    private readonly supportsWebP: boolean,
    private readonly activeSubscriptionPackageIDs: string[]
  ) {}

  async get(id: string): Promise<PremiumOnlineCoupon> {
    try {
      const resp = await selectContent<PremiumOnlineCouponResponse>(
        this.path,
        id
      );
      const coupon = new PremiumOnlineCoupon(
        resp.data.id,
        resp.data.name,
        resp.data.effect,
        resp.data.explanation,
        resp.data.summary,
        resp.data.detail,
        convertOptimizedUrl(resp.data.mainImage, this.isSP, this.supportsWebP),
        convertOptimizedUrl(resp.data.thumbnail, this.isSP, this.supportsWebP),
        convertOptimizedUrl(resp.data.logo, this.isSP, this.supportsWebP),
        undefined, // code
        undefined, // url
        resp.data.displayPattern,
        resp.data.subscriptionPackages.map(p => ({
          id: p.id,
          name: p.packageName
        }))
      );
      // 本リポジトリは利用不可能な限定オンラインクーポンを取得するためのリポジトリであるため、
      // microCMS から利用可能なクーポン情報が返されたとき、undefined を throw する。
      // なお、この throw は直下の catch によりキャッチされる。
      if (coupon.isAvailable(this.activeSubscriptionPackageIDs)) {
        throw undefined;
      }
      return coupon;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error.response?.status;
      }
      throw undefined;
    }
  }

  async getList(
    limit: number,
    offset: number
  ): Promise<CMSResponse<PremiumOnlineCoupon>> {
    const filters = this.activeSubscriptionPackageIDs
      .map(id => `subscriptionPackages[not_contains]${id}`)
      .join('[and]');
    try {
      const resp = await fetchListContents<PremiumOnlineCouponResponse>(
        this.path,
        { limit, offset, filters }
      );
      return {
        limit: resp.data.limit,
        offset: resp.data.offset,
        totalCount: resp.data.totalCount,
        contents: resp.data.contents.map(
          c =>
            new PremiumOnlineCoupon(
              c.id,
              c.name,
              c.effect,
              c.explanation,
              c.summary,
              c.detail,
              convertOptimizedUrl(c.mainImage, this.isSP, this.supportsWebP),
              convertOptimizedUrl(c.thumbnail, this.isSP, this.supportsWebP),
              convertOptimizedUrl(c.logo, this.isSP, this.supportsWebP),
              undefined, // code
              undefined, // url
              c.displayPattern,
              c.subscriptionPackages.map(p => ({
                id: p.id,
                name: p.packageName
              }))
            )
        )
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error.response?.status;
      }
      throw undefined;
    }
  }
}
