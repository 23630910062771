

















































































































































































import CouponUseHistory from '@/components/PremiumCoupon/CouponUseHistory.vue';
import ErrorMessage from '@/components/PremiumCoupon/ErrorMessage.vue';
import ExpendableCouponList from '@/components/PremiumCoupon/ExpendableCoupon/CouponList.vue';
import OnlineCouponList from '@/components/PremiumCoupon/OnlineCoupon/CouponList.vue';
import { IPremiumExpendableCouponRepository } from '@/repositories/interface/IPremiumExpendableCouponRepository';
import { IPremiumExpendableCouponStoreRepository } from '@/repositories/interface/IPremiumExpendableCouponStoreRepository';
import { IPremiumOnlineCouponRepository } from '@/repositories/interface/IPremiumOnlineCouponRepository';
import {
  PremiumExpendableCouponAvailableRepository,
  PremiumExpendableCouponUnavailableRepository
} from '@/repositories/PremiumExpendableCouponRepository';
import { PremiumExpendableCouponStoreRepository } from '@/repositories/PremiumExpendableCouponStoreRepository';
import {
  PremiumOnlineCouponAvailableRepository,
  PremiumOnlineCouponUnavailableRepository
} from '@/repositories/PremiumOnlineCouponRepository';
import { Route } from 'vue-router';
import { Vue, Component } from 'vue-property-decorator';
import {
  getSubscribedPackageIds,
  calcFinalFullTermContractEndDt,
  getSubscriptionPackageEndDt,
  getSubscriptionPackageName
} from '@/common/subscriptionUtils';
import { IPremiumExpendableCouponHistoryRepository } from '@/repositories/interface/IPremiumExpendableCouponHistoryRepository';
import { PremiumExpendableCouponHistoryRepository } from '@/repositories/PremiumExpendableCouponHistoryRepository';

export const PREMIUM_COUPON_REDIRECT_PATH_KEY = 'premium_coupon_redirect_path';
import {
  INFO_SUBSCRIPTION_CLAIMKEY,
  SUBSCRIPTION_PACKAGE_NOT_UPDATE,
  SUBSCRIPTION_CONTRACT_END_HOUR
} from '@/common/constants';

import { ResSubscriptionInfo, SubscriptionContract } from '@/gen';
import { SubscriptionInfo } from '@/models/Subscription';
import { SubscriptionRepository } from '@/repositories/SubscriptionRepository';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';
import { SubscriptionPackageCmsRepository } from '@/repositories/SubscriptionPackageCmsRepository';
import { E_CODE_FAILED_TO_FETCH_INITIALIZE_DATA } from '@/components/PremiumCoupon/ErrorMessage.vue';
import dayjs from 'dayjs';

@Component({
  components: {
    CouponUseHistory,
    ErrorMessage,
    ExpendableCouponList,
    OnlineCouponList
  }
})
export default class P0231 extends Vue {
  readonly defaultTab = 'check-in';

  initialized = false;
  premiumExpendableCouponAvailableRepository: IPremiumExpendableCouponRepository | null = null;
  premiumExpendableCouponUnavailableRepository: IPremiumExpendableCouponRepository | null = null;
  premiumOnlineCouponAvailableRepository: IPremiumOnlineCouponRepository | null = null;
  premiumOnlineCouponUnavailableRepository: IPremiumOnlineCouponRepository | null = null;
  storeRepository: IPremiumExpendableCouponStoreRepository | null = null;
  historyRepository: IPremiumExpendableCouponHistoryRepository | null = null;

  // サブスク登録情報(from odin)
  subscriptionInfo = {} as SubscriptionInfo;
  // パッケージ情報(from microCMS)
  packageList: Array<SubscriptionPackageItem> = [];

  errorCodes: unknown[] = [];

  // サービス終了のお知らせを表示する猶予期間
  readonly alertThresholdForEndingPackage = 2;

  async created(): Promise<void> {
    // tabが未定義の場合、強制的にデフォルトのタブをパスパラメータに設定する
    if (!this.$route.params.tab) {
      this.$router.push({
        name: this.$route.name ? this.$route.name : '',
        params: { tab: this.defaultTab },
        query: this.$route.query
      });
    }
    localStorage.removeItem(PREMIUM_COUPON_REDIRECT_PATH_KEY);

    const config = this.$auth.isAuthenticated
      ? await this.$getConfigWithToken(this.$auth)
      : undefined;
    const isSP = this.$store.state.isSP;
    const supportsWebP = this.$store.state.supportsWebP;
    const activeSubscriptionPackageIDs = this.$auth.isAuthenticated
      ? getSubscribedPackageIds(
          this.$auth.user ? this.$auth.user[INFO_SUBSCRIPTION_CLAIMKEY] : null
        )
      : [];

    this.premiumExpendableCouponAvailableRepository = new PremiumExpendableCouponAvailableRepository(
      config
    );
    this.premiumExpendableCouponUnavailableRepository = new PremiumExpendableCouponUnavailableRepository(
      isSP,
      supportsWebP,
      activeSubscriptionPackageIDs
    );
    this.premiumOnlineCouponAvailableRepository = new PremiumOnlineCouponAvailableRepository(
      config
    );
    this.premiumOnlineCouponUnavailableRepository = new PremiumOnlineCouponUnavailableRepository(
      isSP,
      supportsWebP,
      activeSubscriptionPackageIDs
    );
    this.storeRepository = new PremiumExpendableCouponStoreRepository();
    this.historyRepository = new PremiumExpendableCouponHistoryRepository(
      config
    );

    if (this.$auth.isAuthenticated) {
      const getPackageListPromise = this.getPackageList();
      const getInfoPromise = this.getInfo();
      await Promise.all([getPackageListPromise, getInfoPromise]);
    }

    this.initialized = true;
  }

  get tab(): string {
    return this.$route.params.tab || this.defaultTab;
  }

  get couponID(): string | undefined {
    return this.getQueryValue('coupon-id');
  }

  get storeID(): string | undefined {
    return this.getQueryValue('store-id');
  }

  async login(couponID?: string): Promise<void> {
    if (this.$auth.isAuthenticated) {
      return;
    }

    const path = this.getRedirectPath(this.$route, couponID);
    localStorage.setItem(PREMIUM_COUPON_REDIRECT_PATH_KEY, path);
    return this.$auth.loginWithRedirect({ appState: { targetUrl: path } });
  }

  async signup(): Promise<void> {
    if (this.$auth.isAuthenticated) {
      return;
    }

    const path = this.getRedirectPath(this.$route);
    localStorage.setItem(PREMIUM_COUPON_REDIRECT_PATH_KEY, path);
    return this.$auth.loginWithRedirect({ initialDisplay: 'signup' });
  }

  onError(...errorCodes: unknown[]): void {
    this.errorCodes = errorCodes;
  }

  getRedirectPath(route: Route, couponID?: string): string {
    const path = route.path;
    const _couponID = couponID || this.couponID;
    const _storeID = this.storeID;
    const query = new URLSearchParams();
    if (_couponID) {
      query.append('coupon-id', _couponID);
    }
    if (_storeID) {
      query.append('store-id', _storeID);
    }
    const q = query.toString();
    if (q) {
      return path + '?' + q;
    }
    return path;
  }

  getQueryValue(key: string): string | undefined {
    if (!(key in this.$route.query)) {
      return undefined;
    }
    const couponID = this.$route.query[key];
    if (typeof couponID === 'string') {
      return couponID;
    }
    if (couponID.length === 0) {
      return undefined;
    }
    return couponID[0] || undefined;
  }

  // サブスクリポジトリ for odin
  get subscriptionRepo() {
    return new SubscriptionRepository();
  }

  // サブスクリポジトリ for microCMS
  get subscriptionCmsRepo() {
    return new SubscriptionPackageCmsRepository();
  }

  // サブスクパッケージ一覧の取得 from microCMS
  async getPackageList() {
    const listItem = await this.subscriptionCmsRepo.get();
    if (listItem) {
      this.packageList = listItem;
    } else {
      this.onError(E_CODE_FAILED_TO_FETCH_INITIALIZE_DATA);
    }
  }

  // サブスク登録情報の取得 from odin
  async getInfo() {
    await this.subscriptionRepo
      .getSubscriptionInfo()
      .then(res => {
        this.subscriptionInfo = res;
      })
      .catch((errCode: number) => {
        switch (errCode) {
          case 42206: // サブスク契約が1件も存在しない場合、エラーメッセージはセットせず SubscriptionInfo を初期化する
            this.subscriptionInfo = SubscriptionInfo.valueOf({
              card_info: {
                masked_card_number: '',
                expiry_ym: ''
              },
              subscription_contracts: []
            } as ResSubscriptionInfo);
            break;
          default:
            this.onError(E_CODE_FAILED_TO_FETCH_INITIALIZE_DATA);
            break;
        }
      });
  }
  get contractsToBeUnsubscribed() {
    // 現在登録中のサブスクパッケージ
    const activeSubscriptionPackageIDs = this.$auth.isAuthenticated
      ? getSubscribedPackageIds(this.$auth.user[INFO_SUBSCRIPTION_CLAIMKEY])
      : [];
    // 継続契約フラグが FALSE かつ、現在登録中のサブスクパッケージを返却
    return this.subscriptionInfo?.contracts
      ? this.subscriptionInfo.contracts.filter(
          c =>
            c.continue_flg === false &&
            activeSubscriptionPackageIDs.includes(c.subscription_package_id)
        )
      : [];
  }

  getSubscriptionPackageName(id: string) {
    return getSubscriptionPackageName(this.packageList, id);
  }
  getSubscriptionPackageEndDt(id: string) {
    return getSubscriptionPackageEndDt(this.packageList, id);
  }

  // パッケージ提供終了日時のNヶ月前の場合trueを返す
  isSubscriptionPackageEndingWithinNMonths(contract: SubscriptionContract) {
    // パッケージ情報を取得
    const packageInfo = this.packageList.find(
      p => p.id === contract.subscription_package_id
    );
    if (!packageInfo) {
      return false;
    }
    if (!packageInfo.packageEndDt) {
      return false;
    }

    const FinalFullTermContractEndDt = dayjs(
      calcFinalFullTermContractEndDt(
        packageInfo.payInterval,
        contract.end_dt,
        dayjs(packageInfo.packageEndDt)
          .hour(SUBSCRIPTION_CONTRACT_END_HOUR)
          .toString()
      )
    );

    // サービス終了直前まで更新間隔分の更新を行い続けた場合の契約終了日がNヶ月以上先であればfalse
    if (
      dayjs()
        .add(this.alertThresholdForEndingPackage, 'month')
        .isBefore(FinalFullTermContractEndDt)
    ) {
      return false;
    }
    // サービス終了直前まで更新間隔分の更新を行い続けた場合の契約終了後に更新がないのであれば、trueを返す。
    if (
      packageInfo.packageEndingPattern[0] === SUBSCRIPTION_PACKAGE_NOT_UPDATE
    ) {
      return true;
    }
    return dayjs()
      .add(this.alertThresholdForEndingPackage, 'month')
      .isAfter(
        dayjs(packageInfo.packageEndDt).hour(SUBSCRIPTION_CONTRACT_END_HOUR)
      );
  }

  // 解約予約がされておらずパッケージ提供終了日時のNヶ月前のパッケージを返す
  get contractsForSubscriptionPackageEndingWithinNMonths() {
    // 現在登録中のサブスクパッケージ
    const activeSubscriptionPackageIDs = this.$auth.isAuthenticated
      ? getSubscribedPackageIds(this.$auth.user[INFO_SUBSCRIPTION_CLAIMKEY])
      : [];

    // 継続契約フラグが TRUE かつ、登録中かつ、パッケージ提供終了日時の2ヶ月前のサブスクパッケージを返却
    return this.subscriptionInfo?.contracts
      ? this.subscriptionInfo.contracts.filter(
          c =>
            c.continue_flg === true &&
            activeSubscriptionPackageIDs.includes(c.subscription_package_id) &&
            this.isSubscriptionPackageEndingWithinNMonths(c)
        )
      : [];
  }
}
