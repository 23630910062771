





























































































import CouponDialogFooter from '@/components/PremiumCoupon/CouponDialogFooter.vue';
import { Component, Prop, Vue, PropSync } from 'vue-property-decorator';
import { PremiumOnlineCoupon } from '@/models/PremiumOnlineCoupon';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';

@Component({ components: { CouponDialogFooter } })
export default class PremiumCouponDialog extends Vue {
  @Prop() coupon!: PremiumOnlineCoupon;
  @Prop() readonly state!: 'AVAILABLE' | 'UNAVAILABLE';
  @Prop({ required: true }) readonly packageList!: Array<
    SubscriptionPackageItem
  >;
  @PropSync('dialog') dialog_!: boolean;

  readonly aeonCinemaURL = process.env.VUE_APP_AEON_CINEMA_URL;

  // aeonCinemaKey はイオンシネマに対して POST するキー
  readonly aeonCinemaKey = 'customerId';

  // aeonCinemaValue はイオンシネマに対して POST するバリュー
  get aeonCinemaValue(): string {
    if (!this.$auth.isAuthenticated) {
      return '';
    }

    return this.$store.state.currentUser.uid.replace(/^auth0\|/, '');
  }

  get isAeonCinema(): boolean {
    return (
      typeof this.coupon.url === 'string' &&
      typeof this.aeonCinemaURL === 'string' &&
      this.coupon.url === this.aeonCinemaURL
    );
  }

  // 外部サイト遷移ボタンの表示テキスト判定
  get buttonText(): string {
    if (this.coupon.isDisplayPatternGoToExternalPage) {
      return '専用URLへ (外部サイトへ移動します)';
    } else if (this.coupon.isDisplayPatternComingSoon) {
      return 'クーポン入力画面へ (Coming Soon)';
    } else if (this.coupon.isDisplayPatternGoToServicePage) {
      return 'サービス画面へ';
    }
    return 'クーポン入力画面へ';
  }
}
