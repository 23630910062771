import axios from 'axios';
import { CMSResponse } from '@/models/cms';
import { IPremiumExpendableCouponStoreRepository } from '@/repositories/interface/IPremiumExpendableCouponStoreRepository';
import { PremiumExpendableCouponStore } from '@/models/PremiumExpendableCouponStore';
import { PremiumExpendableCouponStoreResponse } from '@/models/cms/premiumExpendableCouponStore';
import { fetchListContents, selectContent } from '@/common/cms';

export class PremiumExpendableCouponStoreRepository
  implements IPremiumExpendableCouponStoreRepository {
  private readonly path = 'limited-expendable-coupon-stores';

  async get(id: string): Promise<PremiumExpendableCouponStore> {
    try {
      const resp = await selectContent<PremiumExpendableCouponStoreResponse>(
        this.path,
        id
      );
      return new PremiumExpendableCouponStore(
        resp.data.id,
        resp.data.storeName
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error.response?.status;
      }
      throw undefined;
    }
  }

  async search(
    name: string,
    limit = 100,
    offset = 0
  ): Promise<CMSResponse<PremiumExpendableCouponStore>> {
    try {
      const resp = await fetchListContents<
        PremiumExpendableCouponStoreResponse
      >(this.path, {
        limit,
        offset,
        filters: `storeName[contains]${name}`
      });
      return {
        limit: resp.data.limit,
        offset: resp.data.offset,
        totalCount: resp.data.totalCount,
        contents: resp.data.contents.map(
          d => new PremiumExpendableCouponStore(d.id, d.storeName)
        )
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error.response?.status;
      }
      throw undefined;
    }
  }
}
