





































































































import Clock from '@/components/PremiumCoupon/ExpendableCoupon/Clock.vue';
import ErrorMessage from '@/components/PremiumCoupon/ErrorMessage.vue';
import OnceButton from '@/components/OnceButton.vue';
import { PremiumExpendableCoupon } from '@/models/PremiumExpendableCoupon';
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';

@Component({ components: { Clock, OnceButton, ErrorMessage } })
export default class CouponDialogStep2 extends Vue {
  @Prop() readonly coupon!: PremiumExpendableCoupon;
  @Prop() readonly count!: number;
  @Prop() readonly errorCodes!: unknown[];
  @Prop() readonly dialog!: boolean;
  @Prop() readonly state!: 'AVAILABLE' | 'UNAVAILABLE';
  @PropSync('isProcessing', { required: true }) syncIsProcessing!: boolean;

  expenditureCode = '';

  get dialog_(): boolean {
    return this.dialog;
  }

  set dialog_(dialog: boolean) {
    this.expenditureCode = '';
    this.$emit('update:error-codes', []);
    this.$emit('update:dialog', dialog);
  }

  forward(): void {
    this.$emit('forward', this.expenditureCode);
    this.expenditureCode = '';
    this.syncIsProcessing = true;
  }

  back(): void {
    this.expenditureCode = '';
    this.$emit('update:error-codes', []);
    this.$emit('back');
  }
}
