












































































































import ErrorMessage from '@/components/PremiumCoupon/ErrorMessage.vue';
import OnceButton from '@/components/OnceButton.vue';
import { PremiumExpendableCouponStore } from '@/models/PremiumExpendableCouponStore';
import { PremiumExpendableCouponStoreRepository } from '@/repositories/PremiumExpendableCouponStoreRepository';
import { E_CODE_HIT_STORE_NUM_EXCEED_100 } from '@/components/PremiumCoupon/ErrorMessage.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({ components: { OnceButton, ErrorMessage } })
export default class Search extends Vue {
  readonly repository = new PremiumExpendableCouponStoreRepository();

  dialog = false;
  isFormOpen = false;
  storeName = '';
  stores: PremiumExpendableCouponStore[] = [];
  errorCodes: unknown[] = [];

  get dialog_(): boolean {
    return this.dialog;
  }

  set dialog_(dialog: boolean) {
    this.dialog = dialog;
    this.isFormOpen = false;
    this.storeName = '';
    this.stores = [];
  }

  async onSearch() {
    try {
      const resp = await this.repository.search(this.storeName);
      this.errorCodes =
        resp.totalCount > 100 ? [E_CODE_HIT_STORE_NUM_EXCEED_100] : [];
      this.stores = resp.contents;
    } catch (error) {
      this.errorCodes = [error];
    }
  }

  onConfirm(store?: PremiumExpendableCouponStore): void {
    this.$emit('search', store);
    this.dialog_ = false;
  }
}
