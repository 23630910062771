






































































import { IPremiumExpendableCouponHistoryRepository } from '@/repositories/interface/IPremiumExpendableCouponHistoryRepository';
import { PremiumExpendableCouponHistory } from '@/models/PremiumExpendableCouponHistory';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CouponUseHistory extends Vue {
  @Prop() repository!: IPremiumExpendableCouponHistoryRepository;

  readonly pageSize = 20;
  offset = 0;
  totalCount = 0;
  histories: PremiumExpendableCouponHistory[] = [];
  // データローディング中であるか
  loading = false;

  async created(): Promise<void> {
    if (!this.$auth.isAuthenticated) {
      this.$emit('login');
      return;
    }

    return this.fetchPage();
  }

  get hasNextPage(): boolean {
    return this.offset + this.pageSize < this.totalCount;
  }

  get hasPrevPage(): boolean {
    return this.offset > 0;
  }

  async showNextPage(): Promise<void> {
    if (!this.hasNextPage) {
      return;
    }

    this.offset += this.pageSize;
    return this.fetchPage();
  }

  async showPrevPage(): Promise<void> {
    if (!this.hasPrevPage) {
      return;
    }

    this.offset -= this.pageSize;
    return this.fetchPage();
  }

  async fetchPage(): Promise<void> {
    // 関数の最初に loading を true にセット
    this.loading = true;
    try {
      const resp = await this.repository.getList(this.pageSize, this.offset);
      this.histories = resp.contents;
      this.totalCount = resp.totalCount;
    } catch (error) {
      this.$emit('error', error);
    }
    // 関数の最後に loading を false にセット
    this.loading = false;
  }
}
