


















































import CouponListItem from '@/components/PremiumCoupon/OnlineCoupon/CouponListItem.vue';
import CouponListToggle from '@/components/PremiumCoupon/CouponListToggle.vue';
import { IPremiumOnlineCouponRepository } from '@/repositories/interface/IPremiumOnlineCouponRepository';
import { PremiumOnlineCoupon } from '@/models/PremiumOnlineCoupon';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';

@Component({ components: { CouponListItem, CouponListToggle } })
export default class OnlineCoupon extends Vue {
  state: 'AVAILABLE' | 'UNAVAILABLE' = 'AVAILABLE';

  @Prop() repositoryAvailable!: IPremiumOnlineCouponRepository;
  @Prop() repositoryUnavailable!: IPremiumOnlineCouponRepository;
  @Prop({ required: true }) readonly packageList!: Array<
    SubscriptionPackageItem
  >;

  /* coupons */
  couponsAvailable: PremiumOnlineCoupon[] = [];
  couponsUnavailable: PremiumOnlineCoupon[] = [];

  /* pagination properties */
  readonly pageSize = 12;
  totalCountAvailable = 0;
  totalCountUnavailable = 0;

  // ペインの切り替えや、ページネーション等が並列した場合も、常に最後のリクエスト結果のみをデータとして保持・画面に表示するための制御用変数。
  feedingOperationId = 0;

  // データローディング中であるか
  loading = false;

  /* specified coupon */
  @Prop() couponID?: string;
  coupon: PremiumOnlineCoupon | null = null;

  get coupons(): PremiumOnlineCoupon[] {
    return this.state === 'AVAILABLE'
      ? this.couponsAvailable
      : this.couponsUnavailable;
  }

  set coupons(coupons: PremiumOnlineCoupon[]) {
    if (this.state === 'AVAILABLE') {
      this.couponsAvailable = coupons;
    } else {
      this.couponsUnavailable = coupons;
    }
  }

  // 配布元パッケージが1つでも提供中であるクーポンに限り一覧に表示する。
  get couponsToBeListed() {
    return this.coupons.filter(
      c => c.getSubscriptionPackages(this.packageList).length > 0
    );
  }

  get totalCount(): number {
    return this.state === 'AVAILABLE'
      ? this.totalCountAvailable
      : this.totalCountUnavailable;
  }

  set totalCount(count: number) {
    if (this.state === 'AVAILABLE') {
      this.totalCountAvailable = count;
    } else {
      this.totalCountUnavailable = count;
    }
  }

  get repository(): IPremiumOnlineCouponRepository {
    return this.state === 'AVAILABLE'
      ? this.repositoryAvailable
      : this.repositoryUnavailable;
  }

  get morePages(): boolean {
    return this.totalCount > this.coupons.length;
  }

  async created(): Promise<void> {
    // 関数の最初に loading を true にセット
    // 初期ローディングが完了した時点で（初回のfeedPage()が完了した時点で）loading を false にセットする
    this.loading = true;

    if (!this.$auth.isAuthenticated) {
      this.state = 'UNAVAILABLE';
    }

    // クエリパラメタにて couponID が指定されている場合、
    // 指定されたクーポンのモーダルを表示する。
    const couponID = this.couponID;
    if (couponID) {
      // ONE, microCMS にクーポン ID 指定で GET リクエストを発行し、
      // ONE の結果を優先的に採用してモーダルを表示する。
      // リクエストにて発生したエラーは無視される。
      const [resp1, resp2] = await Promise.all([
        this.repositoryAvailable.get(couponID).catch(() => undefined),
        this.repositoryUnavailable.get(couponID).catch(() => undefined)
      ]);
      if (resp1) {
        this.coupon = resp1;
        this.state = 'AVAILABLE';
      } else if (resp2) {
        this.coupon = resp2;
        this.state = 'UNAVAILABLE';
      }
    }

    return this.initializePage();
  }

  async initializePage(): Promise<void> {
    this.coupons = [];
    this.totalCount = 0;
    return this.feedPage();
  }

  async feedPage(): Promise<void> {
    // 関数の最初に loading を true にセット
    this.loading = true;
    // クーポンを取得する非同期処理が完了した後も、コンポーネントで保持しているoperationIDが変更されていなかった場合のみ、取得結果の反映を行う。
    const operationId = ++this.feedingOperationId;
    try {
      const resp = await this.repository.getList(
        this.pageSize,
        this.coupons.length
      );

      if (this.feedingOperationId !== operationId) {
        return;
      }
      this.coupons.push(...resp.contents);
      this.totalCount = resp.totalCount;
    } catch (error) {
      if (this.feedingOperationId !== operationId) {
        return;
      }
      this.$emit('error', error);
    }
    // 関数の最後に loading を false にセット
    this.loading = false;
  }

  async onStateUpdate(state: 'AVAILABLE' | 'UNAVAILABLE'): Promise<void> {
    if (!this.$auth.isAuthenticated) {
      this.state = 'UNAVAILABLE';
      return;
    }

    if (state === this.state) {
      return;
    }

    this.state = state;
    return this.initializePage();
  }
}
