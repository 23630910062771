







import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Clock extends Vue {
  time = new Date(0);
  intervalID = 0;

  created(): void {
    this.intervalID = window.setInterval(() => {
      this.time = new Date();
    }, 1000);
  }

  beforeDestroy(): void {
    window.clearInterval(this.intervalID);
  }
}
