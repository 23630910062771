










































































































































































































import CouponDialogFooter from '@/components/PremiumCoupon/CouponDialogFooter.vue';
import { PremiumExpendableCoupon } from '@/models/PremiumExpendableCoupon';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';
import { SubscriptionInfo } from '@/models/Subscription';
import { calcFinalFullTermContractEndDt } from '@/common/subscriptionUtils';
import {
  SUBSCRIPTION_PACKAGE_NOT_UPDATE,
  SUBSCRIPTION_CONTRACT_END_HOUR
} from '@/common/constants';

import dayjs from 'dayjs';

@Component({ components: { CouponDialogFooter } })
export default class CouponDialogStep1 extends Vue {
  @Prop() readonly coupon!: PremiumExpendableCoupon;
  @Prop() readonly dialog!: boolean;
  @Prop() readonly state!: 'AVAILABLE' | 'UNAVAILABLE';
  @Prop({ required: true }) readonly packageList!: Array<
    SubscriptionPackageItem
  >;
  @Prop({ required: true }) subscriptionInfo!: SubscriptionInfo;

  count = '0';

  get dialog_(): boolean {
    return this.dialog;
  }

  set dialog_(dialog: boolean) {
    this.count = '0';
    this.$emit('update:dialog', dialog);
  }

  forward(): void {
    const count = Number.parseInt(this.count);
    this.count = '0';
    this.$emit('forward', count);
  }

  // サービスが終了することにより、利用可能な期間が短くなる場合、その期限を返却する。短くならない場合、空文字を返却する。
  getShortenExpirationDueToPackageEnding(
    packageId: string,
    couponExpiration: Date
  ): string {
    const packageInfo = this.packageList.find(p => p.id === packageId);
    if (!packageInfo) {
      return '';
    }
    if (!packageInfo.packageEndDt) {
      return '';
    }
    const contract = this.subscriptionInfo.contracts.find(
      c => c.subscription_package_id === packageId
    );
    if (!contract) {
      return '';
    }
    const FinalFullTermContractEndDt = dayjs(
      calcFinalFullTermContractEndDt(
        packageInfo.payInterval,
        contract.end_dt,
        dayjs(packageInfo.packageEndDt)
          .hour(SUBSCRIPTION_CONTRACT_END_HOUR)
          .toString()
      )
    ).hour(SUBSCRIPTION_CONTRACT_END_HOUR);
    if (
      packageInfo.packageEndingPattern[0] === SUBSCRIPTION_PACKAGE_NOT_UPDATE
    ) {
      return FinalFullTermContractEndDt.isBefore(couponExpiration, 'day')
        ? FinalFullTermContractEndDt.toString()
        : '';
    }
    return dayjs(packageInfo.packageEndDt)
      .hour(SUBSCRIPTION_CONTRACT_END_HOUR)
      .isBefore(couponExpiration, 'day')
      ? dayjs(packageInfo.packageEndDt)
          .hour(SUBSCRIPTION_CONTRACT_END_HOUR)
          .toString()
      : '';
  }
}
