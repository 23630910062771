






import { Vue, Component, Prop } from 'vue-property-decorator';

export const E_CODE_INVALID_EXPENDITURE_CODE = '__INVALID_EXPENDITURE_CODE__';
export const E_CODE_USER_HAS_ALREADY_UNSUBSCRIBED_ONE = '40004';
export const E_CODE_USER_TRIED_TO_EXPEND_CODE_MORE_THAN_THEY_HAVE = '40011';
export const E_CODE_REMAINING_QUANTITY_NOT_MATCH = '40012';
export const E_CODE_USER_TRIED_TO_EXPEND_CODE_MORE_THAN_LIMIT_PER_DAY = '40013';
export const E_CODE_COUPON_NOT_EXIST_ON_MICRO_CMS = '40403';
export const E_CODE_FAILED_IN_DB_CONNECTION = '50000';
export const E_CODE_MICRO_CMS_RESPONDED_WITH_ERROR = '50005';
export const E_CODE_HIT_STORE_NUM_EXCEED_100 = '__HIT_STORE_NUM_EXCEED_100__';
export const E_CODE_FAILED_TO_FETCH_INITIALIZE_DATA =
  '__FAILED_TO_FETCH_INITIALIZE_DATA__';

@Component
export default class ErrorMessage extends Vue {
  @Prop() errorCodes!: unknown[];

  /**
   * getMessage は errorCode に対応するエラーメッセージを返す。
   * errorCode は string または number であることが期待される。
   */
  getMessage(errorCode: unknown): string {
    try {
      // errorCode が toString に応答できる場合はそれを呼び出し、その結果を errorCode とする
      errorCode = (errorCode as any).toString();
    } catch {
      // errorCode が toString に応答できない場合は何もせずに処理を進める
    }

    switch (errorCode) {
      case E_CODE_INVALID_EXPENDITURE_CODE:
        return '確認番号が正しくありません。';
      case E_CODE_USER_HAS_ALREADY_UNSUBSCRIBED_ONE:
      case E_CODE_USER_TRIED_TO_EXPEND_CODE_MORE_THAN_THEY_HAVE:
      case E_CODE_REMAINING_QUANTITY_NOT_MATCH:
      case E_CODE_USER_TRIED_TO_EXPEND_CODE_MORE_THAN_LIMIT_PER_DAY:
      case E_CODE_COUPON_NOT_EXIST_ON_MICRO_CMS:
        return this.$msg.get('2000074', {
          errorCode: errorCode
        });
      case E_CODE_FAILED_TO_FETCH_INITIALIZE_DATA:
        return this.$msg.get('2000078');
      case E_CODE_HIT_STORE_NUM_EXCEED_100:
        return this.$msg.get('2000118');
      default:
        return this.$msg.get('2000071', {
          errorCode: errorCode
        });
    }
  }
  /**
   * errorsMessages は重複のないエラーメッセージの配列を返す。
   */
  get errorsMessages(): string[] {
    return [
      ...new Set(this.errorCodes.map(errorCode => this.getMessage(errorCode)))
    ];
  }
}
